import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["itemExpand", "toggleExpand"];
  static values = {
    showText: { type: String, default: "View" },
    hideText: { type: String, default: "Close" },
  };


  toggleDisplay(event) {
    const button = event.currentTarget;
    const card = button.closest(".card-resource");
    const grid = card.closest(".expandable-grid");

    // Close any currently expanded card except the one being clicked
    this.closeOtherCards(card);

    // Check if the card is already expanded
    const isExpanded = card.classList.contains("card--expanded");

    // Toggle expanded class on the clicked card
    card.classList.toggle("card--expanded", !isExpanded);

    // Toggle expanded class on the grid row
    grid.classList.toggle(`has-card-expanded`, !isExpanded);

    // Scroll into view only if the card is not already expanded
    if (!isExpanded) {
      card.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
      button.removeAttribute('data-controller') // Only track once and then remove the metrics controller
    }

    // Update display of itemExpand elements within the clicked card
    card.querySelectorAll("[data-grid-expand-target='itemExpand']").forEach((element) => {
      element.classList.toggle("is-hidden", isExpanded);
    });

    // Update button text
    const showText = button.getAttribute("data-grid-expand-show-text-value");
    const hideText = button.getAttribute("data-grid-expand-hide-text-value");
    button.textContent = isExpanded ? showText : hideText;
  }

  // Close all cards except the one being clicked
  closeOtherCards(currentCard) {
    this.element.querySelectorAll(".card-resource.card--expanded").forEach((expandedCard) => {
      if (expandedCard !== currentCard) {
        const grid = expandedCard.closest(".expandable-grid");

        expandedCard.classList.remove("card--expanded");
        grid.classList.remove(`has-card-expanded`);

        expandedCard.querySelectorAll("[data-grid-expand-target='itemExpand']").forEach((element) => {
          element.classList.add("is-hidden");
        });

        const button = expandedCard.querySelector("[data-action='click->grid-expand#toggleDisplay']");
        if (button) {
          const showText = button.getAttribute("data-grid-expand-show-text-value");
          button.textContent = showText;
        }
      }
    });
  }
}
