import * as ActiveStorage from "@rails/activestorage"
import "./channels/consumer.js"
import 'form-request-submit-polyfill'
import "@hotwired/turbo-rails"
import '@shoelace-style/shoelace/dist/components/tooltip/tooltip'
import "@hotwired/strada"
import Bridge from './/turbo/bridge'
import "trix"
import "@rails/actiontext"
import './controllers'
import './initializers/turbo_confirm.js'
import './initializers/frame_missing_handler.js'
import './initializers/honeybadger.js'
import './initializers/pdf_worker.js'

window.bridge = Bridge

ActiveStorage.start()
