import { BridgeComponent, BridgeElement } from "@hotwired/strada"

export default class extends BridgeComponent {
  static component = "form"
  static targets = [ "submit", "cancel" ]

  submitTargetConnected(target) {
    const submitButton = new BridgeElement(target)
    const title = submitButton.title

    this.send("connect", { title }, () => {
      target.click()
    })
  }

  cancelTargetConnected(target) {
    const cancelButton = new BridgeElement(target)
    const title = cancelButton.title

    this.send("connectCancel", { title })
  }
}
