import PropTypes from 'prop-types'
import React from 'react'

export default class Wheel extends React.Component {
  constructor(props) {
    super(props)
  }

  columns() {
    return [
      {
        field: 'personalFinance',
        start: 0,
        end: 0.25 * Math.PI,
        axis: 0.125 * Math.PI
      },
      {
        field: 'family',
        start: 0.25 * Math.PI,
        end: 0.375 * Math.PI,
        axis: 0.3125 * Math.PI
      },
      {
        field: 'marriage',
        start: 0.375 * Math.PI,
        end: 0.5 * Math.PI,
        axis: 0.4375 * Math.PI
      },
      {
        field: 'disciplingOthers',
        start: 0.5 * Math.PI,
        end: 0.75 * Math.PI,
        axis: 0.625 * Math.PI
      },
      {
        field: 'walkWithGod',
        start: 0.75 * Math.PI,
        end: 1.0 * Math.PI,
        axis: 0.875 * Math.PI
      },
      {
        field: 'rest',
        start: 1.0 * Math.PI,
        end: 1.125 * Math.PI,
        axis: 1.0625 * Math.PI
      },
      {
        field: 'retreat',
        start: 1.125 * Math.PI,
        end: 1.25 * Math.PI,
        axis: 1.1875 * Math.PI
      },
      {
        field: 'fitness',
        start: 1.25 * Math.PI,
        end: 1.375 * Math.PI,
        axis: 1.3125 * Math.PI
      },
      {
        field: 'nutrition',
        start: 1.375 * Math.PI,
        end: 1.5 * Math.PI,
        axis: 1.4375 * Math.PI
      },
      {
        field: 'funAndRecreation',
        start: 1.5 * Math.PI,
        end: 1.75 * Math.PI,
        axis: 1.625 * Math.PI
      },
      {
        field: 'biblicalCommunity',
        start: 1.75 * Math.PI,
        end: 2.0 * Math.PI,
        axis: 1.875 * Math.PI
      }
    ]
  }

  pointFor(r, angle) {
    return {
      x: 300 + r * Math.cos(angle),
      y: 300 - r * Math.sin(angle)
    }
  }

  r(level) {
    if(level == undefined) {
      return 0
    }
    return (level * 23) + 35
  }

  diagramPath() {
    const data = this.props.data
    let radius
    let point = this.pointFor(this.r(data.personalFinance), 0)
    let path = `M ${point.x} ${point.y} ` // move to

    const columns = this.columns()
    columns.forEach((column, index) => {
      const level = data[column.field]
      const lastLevel = columns[index - 1] && data[columns[index - 1].field]
      if (lastLevel != level) {
        point = this.pointFor(this.r(level), column.start)
        path += `L ${point.x} ${point.y} ` // line to
      }
      radius = this.r(level)
      point = this.pointFor(radius, column.end)
      path += `A ${radius} ${radius} 0 0 0 ${point.x} ${point.y} ` // arc to
    })

    if(data.personalFinance != data.biblicalCommunity) {
      point = this.pointFor(this.r(data.personalFinance), 0)
      path += `L ${point.x} ${point.y} ` // line to
    }

    return path
  }

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600" className="wheel">
        <defs>
          <path id="fun_recreation" d="M 300 585 A 285 285 0 0 0 501.5254326381661 501.525432638166"></path>
          <path id="biblical_community" d="M 501.5254326381661 501.525432638166 A 285 285 0 0 0 585 300"></path>
          <path id="personal_finances" d="M 494.45436482630055 105.54563517369945 A 275 275 0 0 1 575 300"></path>
          <path id="marriage_family" d="M 300 25 A 275 275 0 0 1 494.45436482630055 105.54563517369945"></path>
          <path id="discipling_others" d="M 105.5456351736994 105.54563517369945 A 275 275 0 0 1 299.99999999999994 25"></path>
          <path id="walking_with_god" d="M 25 300.00000000000006 A 275 275 0 0 1 105.5456351736994 105.54563517369945"></path>
          <path id="rest_retreat" d="M 15 299.99999999999994 A 285 285 0 0 0 98.47456736183392 501.525432638166"></path>
          <path id="fitness_nutrition" d="M 98.47456736183392 501.525432638166 A 285 285 0 0 0 299.99999999999994 585"></path>
        </defs>
        <g data-name="figure">
          <g data-name="areas">
            <path className="wheel__area--warning" d="M300,473.5A173.5,173.5,0,1,0,126.5,300,173.5,173.5,0,0,0,300,473.5"/>
            <path className="wheel__area--danger" d="M300,403A103,103,0,1,0,197,300,103,103,0,0,0,300,403"/>
          </g>
          <g data-name="subdivisions">
            <line data-name="E-W" className="wheel__division" x1="35" y1="300" x2="565" y2="300"/>
            <line data-name="NW-SE" className="wheel__division" x1="112.5" y1="112.5" x2="487.5" y2="487.5"/>
            <line data-name="N-S" className="wheel__division" x1="300" y1="35" x2="300" y2="565"/>
            <line data-name="NE-SW" className="wheel__division" x1="112.5" y1="487.5" x2="487.5" y2="112.5"/>
            <line data-name="WSW" className="wheel__division wheel__division--intermediate" x1="401.4" y1="55.2" x2="300" y2="300"/>
            <line data-name="SSW" className="wheel__division wheel__division--intermediate" x1="198.6" y1="544.8" x2="300" y2="300"/>
            <line data-name="NNE" className="wheel__division wheel__division--intermediate" x1="55.2" y1="401.4" x2="300" y2="300"/>
          </g>
          <g data-name="rings">
            <circle data-name="ring9" className="wheel__ring" cx="300" cy="300" r="242" />
            <circle data-name="ring8" className="wheel__ring" cx="300" cy="300" r="219" />
            <circle data-name="ring7" className="wheel__ring" cx="300" cy="300" r="196" />
            <circle data-name="ring6" className="wheel__ring wheel__ring--important" cx="300" cy="300" r="173" />
            <circle data-name="ring5" className="wheel__ring" cx="300" cy="300" r="150" />
            <circle data-name="ring4" className="wheel__ring" cx="300" cy="300" r="127" />
            <circle data-name="ring3" className="wheel__ring wheel__ring--important" cx="300" cy="300" r="104" />
            <circle data-name="ring2" className="wheel__ring" cx="300" cy="300" r="81" />
            <circle data-name="ring1" className="wheel__ring" cx="300" cy="300" r="58" />
            { `<!-- master ring -->` }
            <circle data-name="ring10" className="wheel__ring wheel__ring--master" cx="300" cy="300" r="265"/>
          </g>
          <g data-name="roundel">
            <circle className="wheel__roundel" cx="300" cy="300" r="12.8" />
          </g>
        </g>
        <g data-name="diagram">
          <path d={this.diagramPath()} className="wheel__diagram" />
        </g>
        <g data-name="labels">
          <text className="wheel__legend" transform="translate(343, 312)">1</text>
          <text className="wheel__legend" transform="translate(366, 312)">2</text>
          <text className="wheel__legend" transform="translate(389, 312)">3</text>
          <text className="wheel__legend" transform="translate(412, 312)">4</text>
          <text className="wheel__legend" transform="translate(435, 312)">5</text>
          <text className="wheel__legend" transform="translate(458, 312)">6</text>
          <text className="wheel__legend" transform="translate(481, 312)">7</text>
          <text className="wheel__legend" transform="translate(504, 312)">8</text>
          <text className="wheel__legend" transform="translate(527, 312)">9</text>
          <text className="wheel__legend" transform="translate(546, 312)">10</text>

          <text className="wheel__label"><textPath xlinkHref="#fun_recreation" startOffset="50%" textAnchor="middle">{this.props.data.labels.funAndRecreation}</textPath></text>
          <text className="wheel__label"><textPath xlinkHref="#biblical_community" startOffset="50%" textAnchor="middle">{this.props.data.labels.biblicalCommunity}</textPath></text>
          <text className="wheel__label"><textPath xlinkHref="#personal_finances" startOffset="50%" textAnchor="middle">{this.props.data.labels.personalFinance}</textPath></text>
          <text className="wheel__label"><textPath xlinkHref="#marriage_family" startOffset="50%" textAnchor="middle">{this.props.data.labels.marriageFamily}</textPath></text>
          <text className="wheel__label"><textPath xlinkHref="#discipling_others" startOffset="50%" textAnchor="middle">{this.props.data.labels.disciplingOthers}</textPath></text>
          <text className="wheel__label"><textPath xlinkHref="#walking_with_god" startOffset="50%" textAnchor="middle">{this.props.data.labels.walkWithGod}</textPath></text>
          <text className="wheel__label"><textPath xlinkHref="#rest_retreat" startOffset="50%" textAnchor="middle">{this.props.data.labels.restRetreat}</textPath></text>
          <text className="wheel__label"><textPath xlinkHref="#fitness_nutrition" startOffset="50%" textAnchor="middle">{this.props.data.labels.fitnessNutrition}</textPath></text>
        </g>
      </svg>
    )
  }

}
