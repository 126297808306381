import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    document.querySelectorAll('.wrapper--native .page-header__actions a').forEach(link => {
      if (link.target) return

      const path = link.pathname
      if (path.match(/new$/) || path.match(/edit$/)) {
        const icon = path.match(/new$/) ? 'plus' : 'pencil'
        this.postMessage(path, icon)
        link.classList.add('is-hidden')
      }
    })
  }

  postMessage(path, icon) {
    window.webkit?.messageHandlers?.iOSApp?.postMessage({
    name: "ActionButton",
    path: path,
    iconName: icon
    })
  }
}
