import { Controller as StimulusController } from "@hotwired/stimulus"

export default class extends StimulusController {
  connect() {
    // create a HTML5 video element
    const videoElement = this.element
    const newVideoElement = document.createElement('video')
    // Element.attributes is a NamedNodeMap and does not have a forEach method
    for (const attribute of videoElement.attributes) {
      if (attribute.name != 'data-controller') {
        newVideoElement.setAttribute(attribute.name, attribute.value)
      }
    }
    // attach the new video element to the current element's parent
    videoElement.parentElement.appendChild(newVideoElement)
    // and remove the current element
    videoElement.remove()
    newVideoElement.style.visibility = 'visible'
  }
}
