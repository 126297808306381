import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
static targets = ['menu']

  toggleMenu(e) {
    e.preventDefault()

    if (this.menuTarget.classList.contains('dropdown__menu--open')) {
      this.menuTarget.classList.remove('dropdown__menu--open')
    } else {
      this.menuTarget.classList.add('dropdown__menu--open')
      this._canClose()
    }
  }

  _canClose() {
    const outsideClickListener = (event) => {
      if (!event.target.closest('.dropdown') || event.target.closest('.dropdown__menu')) {
        this.menuTarget.classList.remove('dropdown__menu--open')
        removeClickListener();
      }
    };

    const removeClickListener = () => {
      document.body.removeEventListener('click', outsideClickListener);
    };

    document.body.addEventListener('click', outsideClickListener);
  }
}
