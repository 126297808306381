import Chart from 'stimulus-chartjs'

export default class extends Chart {
  static values = {
    includeTotals: Boolean
  }

  get defaultOptions() {
    return {
      plugins: {
        legend: { display: false },
        tooltip: {
          mode: 'index',
          intersect: false,
          callbacks: {
            footer: this.footerCallback.bind(this)
          }
        }
      },
      scales: {
        y: {
          beginAtZero: true,
          stepSize: 2
        }
      },
      animation: {
        duration: 0
      },
      elements: {
        line: {
          tension: 0.2,
          fill: true
        }
      }
    }
  }

  footerCallback(items) {
    if (this.includeTotalsValue) { 
      return 'Total: ' + items.reduce((a, b) => a + b.parsed.y, 0)
    }
  }
}