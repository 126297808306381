import { BridgeComponent } from "@hotwired/strada"
import { BridgeElement } from "@hotwired/strada"

export default class extends BridgeComponent {
  static component = "header-actions"
  static targets = [ "item" ]

  connect() {
    super.connect()
    this.notifyBridgeToDisplayMenu('')
  }

  notifyBridgeToDisplayMenu(event) {
    const items = this.makeMenuItems(this.itemTargets)

    this.send("connect", { items }, message =>  {
      const selectedIndex = message.data.selectedIndex
      const selectedItem = new BridgeElement(this.itemTargets[selectedIndex])

      selectedItem.click()
    })
  }

  makeMenuItems(elements) {
    const items = elements.map((element, index) => this.menuItem(element, index))
    const enabledItems = items.filter(item => item)

    return enabledItems
  }

  menuItem(element, index) {
    const bridgeElement = new BridgeElement(element)

    if (bridgeElement.disabled) return null

    return {
      title: bridgeElement.title,
      icon: bridgeElement.bridgeAttribute('icon'),
      index: index
    }
  }
}
