import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['submitButton', 'total']

  initialize() {
    this.showTotal()
  }

  showTotal() {
    this.totalTargets.forEach((el) => {
      if(el.dataset.surcharged == this.surcharged){
        el.classList.remove('is-hidden')
      } else {
        el.classList.add('is-hidden')
      }
    })
  }

  get surcharged() {
    return this.data.get('surcharged')
  }

  set surcharged(boolean) {
    this.data.set('surcharged', boolean)
    this.showTotal()
  }

  paymentMethodSelected({ target: sourceElement }) {
    this.surcharged = sourceElement.dataset.surcharged
    this.submitButtonTarget.classList.remove('btn--disabled')
    this.submitButtonTarget.removeAttribute('disabled')
  }
}
