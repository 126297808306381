import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = [ "toggle", "item" ]
  static classes = [ "hidden" ]
  static values = {
    showText: { type: String, default: 'Show' },
    hideText: { type: String, default: 'Hide' }
  }

  connect () {
    this.class = this.hasHiddenClass ? this.hiddenClass : 'is-hidden'
  }

  toggle () {
    this.itemTargets.forEach(item => {
      item.classList.toggle(this.class)
    })

    if (this.toggleTarget.innerHTML === this.showTextValue){
      this.toggleTarget.innerHTML = this.hideTextValue
    } else {
      this.toggleTarget.innerHTML = this.showTextValue
    }
  }
}
