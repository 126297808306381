import Flatpickr from 'stimulus-flatpickr'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'

import { english as English } from 'flatpickr/dist/l10n/default.js'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'

export default class extends Flatpickr {
  locales = {
    en: English,
    pt: Portuguese
  }

  connect() {

    this.config = {
      locale: this.locale,
      minDate: this.data.get("min"),
      altInput: true,
      plugins: [
        new monthSelectPlugin({
          altFormat: "F Y", // This is the format shown to the user
          dateFormat: "Y-m-1", // This is the format submitted to the server,
        })
      ]
    }

    super.connect()
  }

  get locale() {
    if (this.locales && this.data.has("locale")) {
      return this.locales[this.data.get("locale")]
    } else {
      return ""
    }
  }
}
