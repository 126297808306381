import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tray']

  toggleTray() {
    if (this.trayTarget.classList.contains('identity__tray--open')) {
      this.trayTarget.classList.remove('identity__tray--open')
    } else {
      this.trayTarget.classList.add('identity__tray--open')
    }
  }
}
