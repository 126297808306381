import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements, useStripe, useElements } from '@stripe/react-stripe-js'
import classNames from 'classnames'

function Form({ formId, backPath }) {
  // region Initialization
  const stripe = useStripe()
  const elements = useElements()

  const holderTypes = ['company', 'individual']

  const [routingNumber, setRoutingNumber] = useState('')
  const [routingNumberError, setRoutingNumberError] = useState('')

  const [accountNumber, setAccountNumber] = useState('')
  const [accountNumberError, setAccountNumberError] = useState('')

  const [accountHolderName, setAccountHolderName] = useState('')
  const [accountHolderNameError, setAccountHolderNameError] = useState('')

  const [accountHolderType, setAccountHolderType] = useState('')
  const [accountHolderTypeError, setAccountHolderTypeError] = useState('')
  // endregion

  // region Helper Functions
  function resetErrors() {
    setRoutingNumberError('')
    setAccountNumberError('')
    setAccountHolderNameError('')
    setAccountHolderTypeError('')
  }

  function validateFields() {
    resetErrors()

    let valid = true

    if (routingNumber.length !== 9) {
      setRoutingNumberError("must be 9 characters long")
      valid = false
    }
    if (!routingNumber) {
      setRoutingNumberError("can't be blank")
      valid = false
    }

    if (!accountNumber) {
      setAccountNumberError("can't be blank")
      valid = false
    }

    if (!accountHolderName) {
      setAccountHolderNameError("can't be blank")
      valid = false
    }

    if (!accountHolderType) {
      setAccountHolderTypeError("can't be blank")
      valid = false
    }

    return valid
  }

  function handleServerError(error) {
    if (error.code === 'routing_number_invalid') {
      setRoutingNumberError(error.message)
    }

    if (error.code === 'account_number_invalid') {
      setAccountNumberError(error.message)
    }

    if (error.code === 'account_holder_name_invalid') {
      setAccountNumberError(error.message)
    }

    if (error.code === 'account_holder_type_invalid') {
      setAccountNumberError(error.message)
    }
  }

  async function handleSubmit(event) {
    event.preventDefault()

    const valid = validateFields()
    if (!valid) { return }

    const options = {
      country: 'US',
      currency: 'usd',
      routing_number: routingNumber,
      account_number: accountNumber,
      account_holder_name: accountHolderName,
      account_holder_type: accountHolderType
    }

    const results = await stripe.createToken('bank_account', options)

    if (results.token) {
      document.querySelector(`#${formId} #payment_method_token`).value = results.token.id
      document.querySelector(`#${formId} #payment_method_account_holder_name`).value = accountHolderName
      document.querySelector(`#${formId}`).submit()
    }

    if (results.error) {
      handleServerError(results.error)
    }
  }

  function renderInputGroup(id, label, placeholder, onChange, error) {
    const classes = classNames('form-group', error ? 'form-group--error' : '')
    return (
      <div className={classes} data-testid={`add-payment-method-${id}`}>
        <label className='form-label' htmlFor={id}>
          <abbr title='required'>*</abbr> {label}
        </label>
        <input
          id={id}
          className='StripeElement'
          type="text"
          placeholder={placeholder}
          onChange={(event) => onChange(event.target.value)}
        />
        { error ? <p className='form-error'>{error}</p> : undefined}
      </div>
    )
  }

  function renderDropdown() {
    const classes = classNames('form-group', accountHolderTypeError ? 'form-group--error' : '')
    return (
      <div className={classes} data-testid='add-payment-method-account-holder-type'>
        <label htmlFor='accountHolderType'>Account Holder Type</label>
        <div className="StripeElement">
          <select id='accountHolderType' onChange={(event) => setAccountHolderType(event.target.value)}>
            <option value=''>Select a holder type</option>
            {holderTypes.map(type => {
              return <option key={type} value={type}>{type}</option>
            })}
          </select>
        </div>
        { accountHolderTypeError ? <p className='form-error'>{accountHolderTypeError}</p> : undefined}
      </div>
    )
  }
  // endregion

  // region render
  if (!(stripe || elements)) {
    return null
  }

  return (
    <form onSubmit={handleSubmit}>
      {renderInputGroup('routing-number', 'Routing Number', '000000000', setRoutingNumber, routingNumberError)}
      {renderInputGroup('account-number', 'Account Number', '000000000000', setAccountNumber, accountNumberError)}
      {renderInputGroup('account-holder-name', 'Account Holder Name', 'Name', setAccountHolderName, accountHolderNameError)}

      {renderDropdown()}

      <a href={backPath} className="btn-secondary">
        Cancel
      </a>
      <button className='btn-primary margin-left-sm' type="submit" disabled={!stripe}>
        Add
      </button>
    </form>
  )
  // endregion
}

Form.propTypes = {
  formId: PropTypes.string.isRequired,
  backPath: PropTypes.string.isRequired
}

function PaymentMethodForm({ apiKey, formId, backPath }) {
  const stripePromise = useMemo(
    () => loadStripe(apiKey),
    [apiKey] // Ensure stripe is only loaded once
  )

  return (
    <Elements stripe={stripePromise}>
      <Form formId={formId} backPath={backPath} />
    </Elements>
  )
}

PaymentMethodForm.propTypes = {
  apiKey: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  backPath: PropTypes.string.isRequired
}

export default PaymentMethodForm
