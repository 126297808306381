import Sortable from "stimulus-sortable"

// Connects to data-controller="sortable-fields"
export default class extends Sortable {
  connect() {
    super.connect()
  }

  async onUpdate(_event) {
    this.element.querySelectorAll('input[data-position-input]').forEach((el, index) => {
      el.value = index + 1
    })
  }
}
