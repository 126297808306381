import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "typeSelect", "categorySelect" ]
  static values = { typeMap: Object }

  refreshTypes() {
    const availableTypes = this.typeMapValue[this.categorySelectTarget.value]

    this.typeSelectTarget.innerHTML = availableTypes.map(type => this.optionTemplate(type)).join('')
  }

  optionTemplate(value) {
    return `<option>${value}</option>`
  }
}
