import ahoy from 'ahoy.js'
import { Controller } from '@hotwired/stimulus'

// Override the default to get around adblockers
ahoy.configure({
  visitsUrl: '/metrics/visits',
  eventsUrl: '/metrics/events'
})

export default class extends Controller {
  static values = {
    action: String,
    model: String,
    id: Number
  }

  track() {
    const name = `${this.actionValue} ${this.modelValue}`

    ahoy.track(name, { model: this.modelValue, id: this.idValue, action: this.actionValue });
  }
}

