import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['field']
  static classes = ['hide']

  connect() {
    this.fieldTargets.forEach(field => {
      if (field.classList.contains(this.hideClass)) {
        this._toggle(true, field)
      }
    })
  }

  perform({target, params: {hide}}) {
    const shouldHide = hide ?? !target.checked
    this.fieldTargets.forEach(this._toggle.bind(this, shouldHide))
  }

  hide() {
    this.perform({params: {hide: true}})
  }

  show() {
    this.perform({params: {hide: false}})
  }

  _toggle(shouldHide, field) {
    const fieldInputs = field.querySelectorAll('input, select, textarea')

    fieldInputs.forEach(input => input.toggleAttribute('disabled', shouldHide))
    field.classList.toggle(this.hideClass, shouldHide)
  }
}
