import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

export default class extends Controller {
  static targets = ['dropdown']
  static values = {
    url: String,
    valueField: {
      type: String,
      default: 'id'
    },
    labelField: {
      type: String,
      default: 'name'
    },
    searchField: {
      type: Array,
      default: ['name']
    },
    maxItems: {
      type: Number,
      default: 1, /* set to null for unlimited */
    },
    serverResponsesReceived: {
      type: Number,
      default: 0,
    },
    withIcons: {
      type: Boolean,
      default: false
    },
  }

  connect() {
    this.tomSelect = new TomSelect(this.dropdownTarget, {
      ...this.baseSettings,
      ...this.apiSettings,
      ...this.iconSettings
    })
    this.tomSelect.positionDropdown()
  }

  disconnect() {
    this.tomSelect.destroy()
  }

  async loadFunction(query, callback) {
    const url = `${this.urlValue}?query=${encodeURIComponent(query)}`
    const response = await fetch(url, this.fetchConfig)
    const json = await response.json()
    this.serverResponsesReceivedValue += 1
    callback(json)
  }

  clear() {
    this.tomSelect.clear()
  }

  get baseSettings() {
    return {
      create: false,
      selectOnTab: true,
      maxItems: this.maxItemsValue,
      valueField: this.valueFieldValue,
      labelField: this.labelFieldValue,
      searchField: this.searchFieldValue,
      dropdownParent: 'body',
    }
  }

  get apiSettings() {
    if (this.hasUrlValue) {
      return { load: this.loadFunction.bind(this) }
    } else {
      return {}
    }
  }

  get fetchConfig() {
    return {
      headers: {
        'Accept': 'application/json'
      }
    }
  }

  get iconSettings() {
    if (!this.withIconsValue) return {}

    return {
      render: {
        option: this.iconTemplate,
        item: this.iconTemplate
      }
    }
  }

  iconTemplate(data, escape) {
    return `<div class="item--with-icon"><img src="${escape(data.name)}" class="svg-select-icon" />${data.id}</div>`
  }
}
