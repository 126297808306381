import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js'

function Form({ formId, backPath }) {
  // region Initialization
  const stripe = useStripe()
  const elements = useElements()

  const [stripeError, setStripeError] = useState('')

  // endregion

  // region Helper Functions

  function _updateCardError(error) {
    if (error) {
      setStripeError(error.message)
    } else {
      setStripeError('')
    }
  }

  async function handleSubmit(event) {
    event.preventDefault()

    const options = {
      address_country: 'US',
      currency: 'usd'
    }
    const cardElement = elements.getElement(CardElement)
    const results = await stripe.createToken(cardElement, options)

    if (results.token) {
      document.querySelector(`#${formId} #payment_method_token`).value = results.token.id
      document.querySelector(`#${formId}`).submit()
    }

    if (results.error) {
      _updateCardError(results.error)
    }
  }
  // endregion

  // region render
  if (!(stripe || elements)) {
    return null
  }

  return (
    <form onSubmit={handleSubmit}>
      <CardElement onChange={event => _updateCardError(event.error) }/>
      {stripeError && <div className="error_message stripe_error">{stripeError}</div>}

      <a href={backPath} className="btn-secondary">
        Cancel
      </a>
      <button className='btn-primary margin-left-sm' type="submit" disabled={!stripe}>
        Add
      </button>
    </form>
  )
  // endregion
}

Form.propTypes = {
  formId: PropTypes.string.isRequired,
  backPath: PropTypes.string.isRequired
}

function NewCardForm({ apiKey, formId, backPath }) {
  const stripePromise = useMemo(
    () => loadStripe(apiKey),
    [apiKey] // Ensure stripe is only loaded once
  )

  return (
    <Elements stripe={stripePromise}>
      <Form formId={formId} backPath={backPath} />
    </Elements>
  )
}

NewCardForm.propTypes = {
  apiKey: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  backPath: PropTypes.string.isRequired
}

export default NewCardForm
