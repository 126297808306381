import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  // adding this class will trigger the load of the wistia player
  load() {
    if (this.element.classList.contains('wistia_embed')) { return }

    this.element.classList.add('wistia_embed')
  }
}
